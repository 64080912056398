
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';

export default defineComponent({
  components: { Avatar },
  props: {
    id: { default: null },
    radius: { default: false },
    width: { default: '50px' },
    height: { default: '50px' },
    acronym: { default: '' },
    url: {
      type: String,
      default: '',
    },
  },
});
