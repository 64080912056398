
import { defineComponent } from 'vue';
import Logo from '@/views/new-design/pages/Agencies/AgencyLogo.vue';
import AgencyDetails from '@/views/new-design/pages/Agencies/AgencyDetails.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import { mapActions } from 'vuex';

import { Actions as BusinessActions } from '@/store/enums/BusinessEnums';
import { Actions as SubscriptionActions } from '@/store/enums/SubscriptionEnums';
import { Business } from '@/models/BusinessModel';
import { ABNLookup } from '@/models/ABNLookupModel';
import toasts from '@/utils/toasts';

export default defineComponent({
  name: 'agency-header-component',
  props: {
    agency: {
      type: Object,
      default: () => {
        return {} as unknown as Business;
      },
    },
  },
  data: () => ({
    agencyDetails: {} as unknown as Business,
    shownDetails: false,
    abnLookupData: {} as unknown as ABNLookup,
    businessApproved: false,
    businessSuspended: false,
    businessDeleted: false,
    abnApproved: 'no',
  }),
  computed: {
    chevronClass() {
      return this.shownDetails ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
    },
    getStatus() {
      if (this.businessApproved) {
        return {
          type: 'success',
          text: 'Approved',
          value: true,
          color: 'text-success',
          negativeIndicator: 'bi bi-x-circle',
        };
      }

      if (this.businessSuspended) {
        return {
          type: 'warning',
          text: 'Suspended',
          value: false,
          color: 'text-warning',
          negativeIndicator: 'bi bi-x-circle',
        };
      }

      if (this.businessDeleted) {
        return {
          type: 'warning',
          text: 'Deleted',
          value: false,
          color: 'text-danger',
          negativeIndicator: 'bi bi-x-circle-fill',
        };
      }

      return {
        type: 'warning',
        text: 'Pending',
        value: false,
        color: 'text-secondary',
        negativeIndicator: 'fas fa-check-circle',
      };
    },
    abnStatus() {
      if (this.abnApproved === 'yes') {
        return {
          type: 'success',
          text: 'ABN',
          value: true,
          color: 'text-success',
        };
      }

      return {
        type: 'warning',
        text: 'ABN',
        value: false,
        color: 'text-danger',
      };
    },
  },
  components: {
    Logo,
    AgencyDetails,
    StatusIndicator,
  },
  methods: {
    ...mapActions({
      makeAffiliate: SubscriptionActions.MAKE_AFFILIATE_SUBSCRIPTION,
      abnLookUp: BusinessActions.ABN_LOOKUP,
      saveABNLookupData: BusinessActions.SAVE_ABN_LOOKUP,
      businessApproval: BusinessActions.APPROVE_BUSINESS,
      businessSuspension: BusinessActions.SUSPEND_BUSINESS,
      deleteBusiness: BusinessActions.DELETE_BUSINESS,
    }),
    async handleMakeAffiliate() {
      const id = this.agencyDetails.id;

      console.log('this.agency', this.agencyDetails);

      return this.makeAffiliate({ id: id })
        .then((data) => {
          console.log('makeAffiliate', data);
        })
        .catch((error) => {
          console.log('makeAffiliate', error);
        });
    },
    async handleABNLookUp() {
      const id = this.agencyDetails.id;

      return await this.abnLookUp(id)
        .then(async (data) => {
          const abnLookupData = await data.data.lookup;

          console.log('abnLookupData', abnLookupData);

          this.abnApproved =
            abnLookupData.AbnStatus.toLowerCase() === 'active' ? 'yes' : 'no';

          const abnLookupParams = {
            id: this.agencyDetails.id,
            abn_status: this.abnApproved,
            abn_lookup: JSON.stringify(abnLookupData),
          };

          this.handleSaveABNLookupData(abnLookupParams);
        })
        .catch((error) => {
          console.log('handleABNLookUp', error);
        });
    },
    async handleBusinessApproval() {
      if (this.agencyDetails?.business_status === 'approved') return;

      const id = this.agencyDetails.id;

      return await this.businessApproval(id)
        .then(async (data) => {
          this.agencyDetails = await data.data;

          console.log('handleBusinessApproval', this.agencyDetails);

          this.businessApproved =
            this.agencyDetails.business_status === 'approved' ? true : false;

          this.businessSuspended = false;
          this.businessDeleted = false;
        })
        .catch((error) => {
          console.log('handleBusinessApproval', error);
        });
    },

    async handleBusinessSuspension() {
      if (this.agencyDetails?.business_status === 'suspended') return;

      const id = this.agencyDetails.id;

      return toasts
        .message('Are you sure to suspend this agency?')
        .then(async ({ isConfirmed, value }) => {
          if (isConfirmed) {
            const payload = {
              id: id,
              suspend_remarks: value,
            };

            return await this.businessSuspension(payload)
              .then(async (data) => {
                console.log('handleBusinessSuspension', data);
                this.agencyDetails = await data.data;
                this.businessSuspended = true;
                this.businessApproved = false;
                this.businessDeleted = false;
              })
              .catch((error) => {
                console.log('handleBusinessSuspension', error);
              });
          }
        });
    },

    async handleDeleteBusiness() {
      const id = this.agencyDetails.id;

      return toasts
        .message('Are you sure to remove this agency?')
        .then(async ({ isConfirmed, value }) => {
          if (isConfirmed) {
            const payload = {
              id: id,
              delete_remarks: value,
            };

            return await this.deleteBusiness(payload)
              .then(async (data) => {
                console.log('handleDeleteBusiness', data);
                this.agencyDetails = await data.data;
                this.businessDeleted = true;
                this.businessApproved = false;
                this.businessSuspended = false;
              })
              .catch((error) => {
                console.log('handleDeleteBusiness', error);
              });
          }
        });
    },

    async handleSaveABNLookupData(abnLookUpData) {
      return await this.saveABNLookupData(abnLookUpData)
        .then(async (data) => {
          //this.abnApproved = data.abn_status;
          console.log('handleSaveABNLookupData', data);
        })
        .catch((error) => {
          console.log('handleSaveABNLookupData', error);
        });
    },
  },

  watch: {
    agency: {
      async handler(values) {
        const agencyValue = await values;
        this.agencyDetails = agencyValue;

        console.log('agency handler', agencyValue);
        console.log('agency handler abn_lookup', agencyValue.abn_lookup);

        this.abnApproved =
          agencyValue.abn_status !== 'null' && agencyValue.abn_status === 'yes'
            ? 'yes'
            : 'no';

        this.abnLookupData =
          agencyValue.abn_lookup !== 'null' && agencyValue.abn_lookup !== ''
            ? agencyValue.abn_lookup
            : '';

        switch (agencyValue.business_status) {
          case 'approved':
            this.businessApproved = true;
            this.businessSuspended = false;
            this.businessDeleted = false;
            break;

          case 'suspended':
            this.businessApproved = false;
            this.businessSuspended = true;
            this.businessDeleted = false;
            break;

          case 'deleted':
            this.businessApproved = false;
            this.businessSuspended = false;
            this.businessDeleted = true;
            break;

          default:
            this.businessApproved = false;
            this.businessSuspended = false;
            this.businessDeleted = false;
            break;
        }

        if (
          agencyValue.abn_lookup === null ||
          agencyValue.abn_lookup === 'null' ||
          agencyValue.abn_lookup === '{}' ||
          agencyValue.abn_lookup === ''
        ) {
          await this.handleABNLookUp();
        }
      },
      deep: true,
    },
  },
});
