
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'alert-danger',
  props: {
    dismiss: {
      type: Boolean,
      default: true,
    },
    alertTitle: {
      type: String,
      default: '',
    },
    alertDescription: {
      type: String,
      default: '',
    },
    alertTime: {
      type: String,
      default: '',
    },
    alertUser: {
      type: String,
      default: '',
    },
    alertIcon: {
      type: String,
      default: 'bi bi-exclamation-circle-fill',
    },
  },
});
