
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

export default defineComponent({
  props: {
    classAvatar: {
      type: String,
      default: 'avatar-small align-self-middle',
    },
    size: {
      type: String,
      default: '100',
    },
    useIcon: {
      type: Boolean,
      default: () => false,
    },
    imageFile: {
      type: String,
      default: '',
    },
    acronym: {
      type: String,
      default: '',
    },
  },
  computed: {
    defaultAcronym() {
      return this.acronym ? this.acronym : '';
    },
    imageSrc() {
      return this.imageFile ? this.imageFile : '';
    },
  },
});
