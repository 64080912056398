
import { defineComponent } from 'vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import AlertWarning from '@/components/alerts/AlertWarning.vue';
import AlertDanger from '@/components/alerts/AlertDanger.vue';
import { capitalize, removeSpaces, formatABN, formatACN } from '@/utils/text';
import timeUtil from '@/utils/time';
import { Business } from '@/models/BusinessModel';

export default defineComponent({
  name: 'agency-details-component',
  components: {
    StatusIndicator,
    AlertWarning,
    AlertDanger,
  },
  props: {
    agency: {
      type: Object,
      default: () => {
        return {} as unknown as Business;
      },
    },
    businessApproved: {
      type: Boolean,
      default: false,
    },
    businessSuspended: {
      type: Boolean,
      default: false,
    },
    businessDeleted: {
      type: Boolean,
      default: false,
    },
    abnApproved: {
      type: String,
      default: 'no',
    },
  },
  computed: {
    getStatus() {
      if (this.businessApproved) {
        return {
          type: 'success',
          text: 'Approved',
          value: true,
          color: 'text-success',
          negativeIndicator: 'bi bi-x-circle',
        };
      }

      if (this.businessSuspended) {
        return {
          type: 'warning',
          text: 'Suspended',
          value: false,
          color: 'text-warning',
          negativeIndicator: 'bi bi-x-circle',
        };
      }

      if (this.businessDeleted) {
        return {
          type: 'warning',
          text: 'Deleted',
          value: false,
          color: 'text-danger',
          negativeIndicator: 'bi bi-x-circle-fill',
        };
      }

      return {
        type: 'warning',
        text: 'Pending',
        value: false,
        color: 'text-secondary',
        negativeIndicator: 'fas fa-check-circle',
      };
    },
    abnStatus() {
      if (this.abnApproved === 'yes') {
        return {
          type: 'success',
          text: 'ABN',
          value: true,
          color: 'text-success',
        };
      }

      return {
        type: 'warning',
        text: 'ABN',
        value: false,
        color: 'text-danger',
      };
    },
    unformattedMobileNumber() {
      return removeSpaces(this.agency?.business_mobile_number);
    },
    unformattedOfficeNumber() {
      return removeSpaces(this.agency?.business_mobile_number);
    },
    formattedACN() {
      return formatACN(this.agency?.acn);
    },
    formattedABN() {
      return formatABN(this.agency?.abn);
    },
  },
  methods: {
    makeCapital(str) {
      return capitalize(str);
    },
    formattedDate(date) {
      return timeUtil.dateFormat(date, 'aus');
    },
  },
});
