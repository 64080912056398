
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    color: { default: 'red' },
    text: { default: '' },
    count: { default: 0 },
    icon: { default: 'fas fa-building' },
  },
});
