
import { defineComponent } from 'vue';
import BusinessLogo from '@/components/avatars/BusinessLogo.vue';

export default defineComponent({
  components: { BusinessLogo },
  props: {
    data: {
      type: Object,
      default: () => {
        return {} as any;
      },
    },
  },

  data() {
    return {
      url: require(`@/assets/new-design/images/no-logo.png`),
    };
  },
  computed: {
    acronym() {
      const businessAcronyn = this.data?.business_name
        ?.match(/\b(\w)/g)
        ?.join('');

      return this.data ? businessAcronyn?.toUpperCase() : '';
    },
    imageSource() {
      return this.data?.logo_src ? this.data?.logo_src : '';
    },
  },
});
