import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BusinessLogo = _resolveComponent("BusinessLogo")!

  return (_openBlock(), _createBlock(_component_BusinessLogo, {
    imageFile: _ctx.imageSource,
    acronym: _ctx.acronym,
    size: "65px",
    height: "auto"
  }, null, 8, ["imageFile", "acronym"]))
}