
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import WidgetCountSummary from '@/views/new-design/pages/Dashboard/Widgets/WidgetCountSummary.vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import { Actions, Getters, Mutations } from '@/store/enums/AgencyEnums';
import { mapActions, mapGetters } from 'vuex';
import UserAvatar from '@/views/new-design/pages/Agencies/UserAvatar.vue';
import Header from '@/views/new-design/pages/Agencies/Header.vue';
import {
  Actions as ReferenceActions,
  Getters as ReferenceGetters,
  Mutations as ReferenceMutations,
} from '@/store/enums/ReferenceEnums';
import { acronym } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  components: {
    Card,
    DataTable,
    WidgetCountSummary,
    Breadcrumb,
    UserAvatar,
    Header,
  },
  data: () => {
    return {
      table: {
        currentPage: 1,
        totalPages: 0,
        perPage: 0,
        total: 0,
        items: [],
      },
      reviewedTable: {
        currentPage: 1,
        totalPages: 10,
        perPage: 15,
        total: 0,
        items: [],
        ordering: false,
      },
      requestedTable: {
        currentPage: 1,
        totalPages: 10,
        perPage: 15,
        total: 0,
        items: [],
        ordering: false,
      },
      activeMenu: 0,
      loadingReferences: false,
      loadingRequested: false,
    };
  },
  computed: {
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    userTableHeaders() {
      return [
        { name: 'Users', key: 'user' },
        { name: 'Role', key: 'role', hidden: this.isMobile },
      ];
    },
    reviewedTableHeaders() {
      return [
        { name: 'Tenant Name', key: 'name' },
        { name: 'Tenant Rating', key: 'rating' },
        { name: 'Submitted Date', key: 'date', hidden: this.isMobile },
        { name: 'Submitted By', key: 'agent_name', hidden: this.isMobile },
      ];
    },
    requestedTableHeaders() {
      return [
        { name: 'Tenant Name', key: 'name' },
        { name: 'Tenant Rating', key: 'rating' },
        { name: 'Submitted Date', key: 'date', hidden: this.isMobile },
        { name: 'Submitted By', key: 'agency', hidden: this.isMobile },
        {
          name: 'Requested Date',
          key: 'requested_date',
          hidden: this.isMobile,
        },
        { name: 'Requested By', key: 'requested_by', hidden: this.isMobile },
      ];
    },
    breadcrumbs() {
      return [
        {
          to: '/agencies',
          text: 'Agencies List',
          current: false,
        },
        {
          to: `/agencies/${this.$route.params.agent_id}`,
          text: 'Agency Details',
          current: true,
        },
      ];
    },
    ...mapGetters({
      agency: Getters.GET_SINGLE_AGENCY,
      users: Getters.GET_AGENCY_USERS,
      errors: Getters.GET_ERRORS,
      references: ReferenceGetters.GET_REFERENCES_LISTS,
      agencyReferences: Getters.GET_REFERENCES,
    }),
    sectionTitle() {
      const titles = [
        'Tenants Reviewed',
        'Tenant Reference Requested',
        'Users',
      ];
      return titles[this.activeMenu];
    },
    formattedReferences() {
      return this.agencyReferences
        .filter((item) => {
          return item.user?.business_id == this.agency?.id;
        })
        .map((item) => {
          item.name = `${item.tenant.first_name} ${item.tenant.last_name}`;
          item.rating = parseFloat(item.average_rating);
          item.date = item.date_created;
          item.agent_name = `${item.user.first_name} ${item.user.last_name}`;

          return item;
        });
    },
    requestedReferences() {
      return this.agencyReferences
        .filter((item) => {
          return (
            item.request_reference_id &&
            item?.tenant_reference_request?.user?.business_id == this.agency.id
          );
        })
        .map((item) => {
          item.name = `${item.tenant.first_name} ${item.tenant.last_name}`;
          item.rating = parseFloat(item.average_rating);
          item.date = item.date_created;
          item.agency = item.user.business_name;
          item.agent_name = `${item?.tenant_reference_request?.user.first_name} ${item?.tenant_reference_request?.user.last_name}`;
          item.requested_by =
            item?.tenant_reference_request?.request_from == 'agent'
              ? item?.tenant_reference_request?.user.business_name
              : 'Tenant';
          item.requested_date = item?.tenant_reference_request?.submitted_at;

          return item;
        });
    },
  },
  watch: {
    formattedReferences: {
      handler(value) {
        if (value && value.length) {
          this.reviewedTable.items = value;
          this.reviewedTable.total = value.length;
          this.loadingReferences = false;
        }
      },
      deep: true,
    },
    requestedReferences: {
      handler(value) {
        if (value && value.length) {
          this.requestedTable.items = value;
          this.requestedTable.total = value.length;
          this.loadingRequested = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchAgency: Actions.FETCH_SINGLE_AGENCY,
      fetchAgencyUsers: Actions.FETCH_AGENCY_USERS,
      fetchReferences: ReferenceActions.FETCH_REFERENCES,
      fetchAgencyReferences: Actions.FETCH_REFERENCES,
    }),
    async getAgencyDetails() {
      await this.fetchAgency({ id: this.$route.params.agent_id }).catch(() => {
        console.log('ERROR');
      });
    },
    formatUserList() {
      return this.users.users.map((item) => {
        item.user = `${item.first_name} ${item.last_name}`;
        item.role = this.getRole(item.roles[0]);

        return item;
      });
    },
    async getUsers() {
      await this.fetchAgencyUsers({ id: this.$route.params.agent_id }).then(
        () => {
          this.table.items = this.formatUserList();
          this.table.currentPage = this.users.pagination.current_page;
          this.table.totalPages = this.users.pagination.total_pages;
          this.table.perPage = this.users.pagination.per_page;
          this.table.total = this.users.pagination.total;
          console.log(this.getUsers);
        }
      );
    },
    async getTenantReferences() {
      this.loadingReferences = true;
      await this.fetchReferences({ returnAll: true })
        .then(() => {
          this.reviewedTable.items = this.formattedReferences;
          this.reviewedTable.total = this.formattedReferences.length;
        })
        .then(() => (this.loadingReferences = false));
    },
    async getAgentReferences() {
      this.loadingRequested = true;
      this.loadingReferences = true;
      await this.fetchAgencyReferences({
        id: this.$route.params.agent_id,
        returnAll: true,
      })
        .then(() => {
          // this.requestedTable.items = this.requestedReferences;
          // this.requestedTable.total = this.requestedReferences?.length;

          this.reviewedTable.items = this.formattedReferences;
          this.reviewedTable.total = this.formattedReferences.length;
        })
        .finally(() => {
          this.loadingRequested = false;
          this.loadingReferences = false;
        });
    },
    onPageChanged() {
      console.log('test');
    },
    showTableData(menu) {
      this.activeMenu = menu;
    },
    viewReport(ref: any) {
      this.$router.push({
        name: 'view-tenant-reference',
        params: { tenant_id: ref.tenant.id, tenant_ref_id: ref.reference_no },
      });
    },
    getRole(role) {
      return role == 'principal' ? 'Principal' : 'Property Manager';
    },
    getAcronym(name = '') {
      return acronym(name);
    },
  },
  async mounted() {
    await this.getAgencyDetails();
    await this.getUsers();
    await this.getAgentReferences();
    // await this.getTenantReferences();
  },
});
