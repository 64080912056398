import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image-slot" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_image, {
    src: _ctx.imageSrc,
    class: _normalizeClass(_ctx.classAvatar),
    size: _ctx.size,
    fit: "fit",
    style: {"max-width":"100px"}
  }, {
    error: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.useIcon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/new-design/images/default-user.png')
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.defaultAcronym), 1))
      ])
    ]),
    _: 1
  }, 8, ["src", "class", "size"]))
}